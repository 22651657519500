<template>
  <div class="error" v-if="sequence == null">
     <h1>404</h1>
    <p><strong>Sequence not found.</strong></p>
    <p>The requested sequence could not be found.</p>
  </div>
  <div class="sequence-view" v-else>
    <div class="info">
      <h1>{{ sequence.title }}</h1>
      <i><p>Song by: {{ sequence.artist }}</p>
      <p>Sequenced by: {{ sequence.creator }}</p></i>
      <p>{{ sequence.description }}</p>
    </div>
    <Product-Video
      :video-type="sequence.videoType"
      :video-src="sequence.videoSrc"
      :id="sequence.seqId"
    />
    <div class="buttons">
      <a :href="sequence.fileLink">Download Sequence <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
</svg></a>
      <a v-if="sequence.musicLink != ''" :href="sequence.musicLink">Get Music <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-music-note-beamed" viewBox="0 0 16 16">
  <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"/>
  <path fill-rule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"/>
  <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z"/>
</svg></a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductVideo from "../components/Product-Video.vue";

export default {
  components: { ProductVideo },
  name: "Sequence-View",
  props: {
    id: String,
  },
  methods:{
    ...mapActions(['getSequences'])
  },
  created() {
    this.getSequences();
  },
  computed: {
    ...mapGetters(["sequenceById"]),
    sequence() {
      return this.sequenceById(this.id);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
.error{
  text-align: center;
}
.sequence-view {
  padding: 10px;
  display: grid;
  grid-template: repeat(4, auto) / repeat(2, 1fr);
  grid-gap: 10px;
  max-width: 1600px;
  margin: auto;
}
h1,
p {
  margin: 5px;
}
.video-container {
  aspect-ratio: 16/9;
  height: auto;
  grid-column: 1/2;
  grid-row: 1/3;
}
.info {
  grid-column: 2/3;
  grid-row: 1/2;
}
.buttons {
  grid-column: 2/3;
  grid-row: 2/3;
  display: flex;
}
a {
  color: white;
  font-family: "Open Sans Condensed", sans-serif;
  padding: 10px;
  background: #2c3e50;
  font-size: 17px;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  margin: 5px;
  display: inline-block;
  align-self: flex-end;
}
a:hover {
  background: #415568;
}
@media screen and (max-width: 700px) {
  .video-container {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .info {
    grid-column: 1/3;
    text-align: center;
  }
  .buttons {
    grid-column: 1/3;
    grid-row: 3/4;
    justify-content: center;
  }
}
</style>